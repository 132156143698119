import axiosIns from '@/libs/axios'

const controller = 'auth'

export default {
  async getUser() {
    return axiosIns.get('authenticate').then(res => res.data)
  },
  async activateAccount(key) {
    return axiosIns.get(`${controller}/activate-by-key?key=${key}`).then(res => res.data)
  },
  async sendActivateAccount(email) {
    return axiosIns.post(`resend-password?email=${email}`).then(res => res.data)
  },
  async resetPasswordInit(req) {
    return axiosIns.post(`${controller}/recover`, req).then(res => res.data)
  },
}
